import Vue from 'vue'
import Vuex from 'vuex'

import EstateIndex from './estates/index'
import UserIndex   from './users/user'
import Alert from './alert'

Vue.use(Vuex)

export default new Vuex.Store({
    
    modules: {
      EstateIndex,
      UserIndex,
      Alert,
    },

})