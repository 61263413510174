<template>
<section class="mobile-search-modal">
    <div class="heading">
        <div class="heading-close" @click="closeModal">
            <unicon name="multiply" fill="#7DA2C5" height="18" width="18" class="close-btn"></unicon>
        </div>
        <div class="heading-text">
            <h2>عروض عقارية</h2>
        </div>
        <div class="heading-close">
            <unicon name="redo" fill="#7DA2C5" height="18" width="18" class="close-btn"></unicon>
        </div>
    </div>
    <div class="mobile-drop-down">
       <loading-overlay
        color="#F36A22"
        :active.sync="loading"
        :is-full-page="true"
        :can-cancel="true"
      >
      </loading-overlay>
        <div class="mobile-drop-down-body">
            <div class="drop-switch">
                <div class="drop-item drop-rent" :class="{ 'active-drop': activeDrop == 1 }" @click="selectDrop(1)">
                    <span class="drop-text"> للايجار </span>
                </div>
                <div class="drop-item drop-sale" :class="{ 'active-drop': activeDrop == 2 }" @click="selectDrop(2)">
                    <div class="drop-text">للبيع</div>
                </div>
            </div>

            <div class="search-types">
                <div class="row">
                    <div class="col-6" v-for="ty in lists.asset_types" :key="ty.id">
                        <div class="search-type" @click="searchAssetOffer(ty.id)">
                            {{ ty.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    data() {
        return {
            activeDrop: 1,
        };
    },
    components: {
        "loading-overlay": Loading,
    },
    computed: {
        ...mapGetters("EstateIndex", ["lists", "searchValues" , "loading"]),
    },
    methods: {
        ...mapActions("EstateIndex", [
            "performSearch",
        ]),
        selectDrop(index) {
            this.activeDrop = index;
        },
        searchAssetOffer(type_id) {

            var offer_type = ''
            if (this.activeDrop == 1)
                offer_type = 'إيجار'
            else
                offer_type = 'بيع'

            this.searchValues.asset.asset_type_id = type_id;
            this.searchValues.asset.offer_type = offer_type;
            this.performSearch().then(() => {
                this.closeModal();
            });
        },
        closeModal() {
            this.$modal.hideAll();
        },
    },
};
</script>

<style lang="scss" scoped>
.mobile-search-modal {
    direction: rtl;
    height: 100%;
    display: flex;
    flex-direction: column;
    // min-height: 100vh;
    // overflow: auto;
}

.heading {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 15px;
    border-bottom: 1px solid #ccc;

    .heading-close {
        height: 28px;
        width: 28px;
        text-align: center;
        border: 1px solid #eaeaea;
        background-color: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .heading-button {
        button {
            border: none;
            background-color: $primary;
            padding: 4px 8px;
            color: white;
        }
    }
}

.drop-switch {
    width: 90%;
    margin: 10px auto;
    display: flex;
    justify-content: space-evenly;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 1px;

    .drop-item {
        transition: all 0.7s;
        cursor: pointer;
        width: 100%;
        text-align: center;
        padding: 4px;
        font-weight: normal;
    }

    .active-drop {
        background: $primary;
        color: white;
        border-radius: 6px;
        // overflow: hidden;
    }
}

.search-types {
    width: 90%;
    margin: 20px auto 10px auto;
    border-top: 1px solid #ccc;

    .search-type {
        margin: 3px;
        // border: 1px solid #ccc;

        padding: 3px;
        border-radius: 10px;
        cursor: pointer;
        text-align: center;
        width: 100%;
        margin-bottom: 8px;
        background-color: white;
        color: $primary;
        box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);

        &:hover {
            background-color: $primary;
            color: white;
        }
    }
}
</style>
