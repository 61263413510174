import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/landing',
    name: 'Landing',
    component: () => import( '../views/LandingView.vue')

  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/AboutView.vue')
  },
  {
    path: '/details/:id',
    name: 'details',
    component: () => import( '../views/HomeView.vue')
  },
  {
    path: '/add',
    name: 'add',
    meta: {
      secure: true,
    },
    component: () => import( '../views/HomeView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import( '../views/ContactView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/LoginView.vue')
  },
  {
    path: '/terms',
    name: 'Terms of Use',
    component: () => import( '../views/TermsOfUseView.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import( '../views/PrivacyPolicyView.vue')
  },
  {
    path: '/complaints',
    name: 'Complaints',
    component: () => import( '../views/ComplaintsView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( '../views/RegisterView.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import( '../views/JoinView.vue')
  },
  {
    path: '/rate',
    name: 'rate',
    component: () => import( '../views/RateView.vue')
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: () => import( '../components/PdfComponent.vue')
  },
  
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.secure)) {
    if (!localStorage.getItem("deb-us")) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } 
  else {
    next();
  }
});


const DEFAULT_TITLE = 'شركة تغطية العقارية';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});


export default router
