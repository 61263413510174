<template>
<section class="preview-modal">
    <h2>إضافة سومة</h2>
    <FormulateForm @submit="submitView">
        <FormulateInput v-model="addValues.soom_price" type="number" label="السعر" validation="required" />

        <div class="submit-container">
            <button type="submit" class="preview-submit">تاكيد</button>
        </div>
    </FormulateForm>
</section>
</template>

<script>
import {
    mapActions
} from "vuex";
export default {
    data() {
        return {
            addValues: {
                soom_price: undefined,
                user_id: JSON.parse(localStorage.getItem("deb-us")).id,
                u_i: 1,
                asset_id: this.$route.params.id ,
                acceptable : 0
            },
        };
    },

    methods: {
        ...mapActions("EstateIndex", [
            "saveSoom",
           
        ]),
        submitView() {
            this.saveSoom(this.addValues)
        },
    },
};
</script>

<style lang="scss" scoped>
.preview-modal {
    direction: rtl;
    padding: 20px;
}

.submit-container {
    display: flex;
    justify-content: flex-end;
}

.preview-submit {
    background-color: $primary;
    color: white;
    padding: 4px 18px;
    border: none;
    border-radius: 4px;
}
</style>
