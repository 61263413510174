<template>
  <section class="nav-bar">
    <div class="containar">
      <div class="row nav-bar-row">
        <div class="col-lg-2 col-md-0 logo-side-container">
          <div class="logo-side">
            <img
              src="@/assets/logos/logoHome.png"
              alt=""
              @click="$router.push('/')"
              
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 links-side-container">
          <div class="links-side">
            <ul>
              <li>
                <router-link to="/">
                  {{ $t("navBar.home") }}
                </router-link>
              </li>
              <!-- <li>
                <router-link to="/about">
                  {{ $t("navBar.about") }}
                </router-link>
              </li> -->
              
              <!-- <li>
                <router-link to="/contact">
                  {{ $t("navBar.contact") }}
                </router-link>
              </li>
              <li>
                <router-link to="/join">
                  {{ $t("navBar.join") }}
                </router-link>
              </li> -->
              <li>
                <router-link to="/rate">
                  {{ $t("navBar.rates") }}
                </router-link>
              </li>
              <li>
                <router-link to="/privacy">
                  {{ $t("navBar.privcay") }}
                </router-link>
              </li>
              <li>
                <router-link to="/terms">
                  {{ $t("navBar.terms") }}
                </router-link>
              </li>
              <li>
                <router-link to="/complaints">
                  {{ $t("navBar.complaints") }}
                </router-link>
              </li>
              <!-- <li>
                <router-link to="/request">
                  {{ $t("navBar.request") }}
                </router-link>
              </li> -->
            </ul>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 buttons-side-container">
          <div class="buttons-side">
            <!-- <button class="btn-primary" @click="checkLogin">
              {{ $t("navBar.addHouse") }}
            </button> -->
            <button class="btn-white">
              <span v-if="$i18n.locale == 'ar'" @click="changeLanguage('en')"
                >English</span
              >
              <span v-if="$i18n.locale == 'en'" @click="changeLanguage('ar')"
                >عربي</span
              >
              <!-- {{$i18n.locale}} -->
            </button>
            <div class="btn-sign-in-container">
              <button
                v-if="!user"
                class="btn-white btn-sign-in"
                @click="
                  $router.push({
                    path: '/login',
                  })
                "
              >
                <span>
                  <unicon
                    name="signin"
                    fill="#F36A22"
                    height="18"
                    width="18"
                  ></unicon>
                </span>
                {{ $t("navBar.signIn") }}
              </button>
              <button
                v-if="user"
                class="btn-white btn-sign-in"
                @click="showAcountBox"
              >
                <span>
                  <unicon
                    name="user"
                    fill="#F36A22"
                    height="18"
                    width="18"
                  ></unicon>
                </span>
                {{ $t("navBar.myAccount") }}
              </button>
              <div
                class="drop-down"
                v-if="acountBox"
                id="drop_down"
                v-click-outside="onClickOutside"
              >
                <div class="drop-down-body">
                  <ul>
                    <!-- <li>
                      <router-link to="/">
                        <span class="icon">
                          <unicon
                            name="user"
                            fill="#555555"
                            height="18"
                            width="18"
                          ></unicon>
                        </span>
                        <span class="text"> البروفايل </span>
                      </router-link>
                    </li>
                    -->
                    <li @click.prevent="performLogout">
                      <router-link to="/"
                        ><span class="icon">
                          <unicon
                            name="sign-out-alt"
                            fill="#555555"
                            height="18"
                            width="18"
                          ></unicon></span
                        ><span class="text"> تسجيل خروج</span></router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AddEstateModal from "./AddEstateModal";
import NotLoggedModal from "./NotLoggedModal";
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      acountBox: false,
      center: {
        lat: 15.5970502,
        lng: 32.5583356,
        z: 40,
      },
    };
  },
  computed: {
    ...mapGetters("UserIndex", ["user_id", "user"]),
  },
  methods: {
    showAcountBox() {
      this.acountBox = true;
    },

    onClickOutside() {
      this.acountBox = false;
    },
    checkLogin() {
      if (this.user) {
        this.showModalAdd();
      } else {
        this.showLoggedModal();
      }
    },
    showLoggedModal() {
      this.$modal.show(
        NotLoggedModal,
        {},
        {
          adaptive: true,
          height: "auto",
          scrollable: false,
        }
      );
    },
    showModalAdd() {
      var screenWidth = window.screen.width;
      this.$router.push({
        path: "/add",
      });

      this.$modal.show(
        AddEstateModal,
        {},
        {
          width: screenWidth < 992 ? "100%" : "900px",
          height: "auto",
          scrollable: true,
        },

        {
          "before-close": () => {
            this.$router.push({
              path: "/",
            });
          },
        }
      );
    },
    closeAddModal() {
      this.$refs.addModal.close();
    },
    changeLanguage(locale) {
      this.$root.$i18n.locale = locale;
      localStorage.setItem("deb-locale", locale);
    },
    performLogout() {
      localStorage.removeItem("deb-us");
      localStorage.removeItem("deb-to");
      this.$router.go('/')
    },
  },
  created() {},
  mounted() {
    // this.showModalAdd();
  },
};
</script>

<style lang="scss">
@media (max-width: 992px) {
  .links-side {
    display: none;
  }
}

@media (max-width: 1130px) {
  .logo-side-container {
    display: none;
  }
}

.sweet-box-actions {
  display: none;
}

.sweet-content {
  padding-top: 0 !important;
}

.nav-bar {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;

  .containar {
    width: 98%;
    margin: auto;
  }

  .nav-bar-row {
    justify-content: space-between;
    align-items: center;
  }

  .logo-side {
    text-align: start;
    width: 100%;
    padding: 5px 10px;
    
    img {
      height: 45px;
    width: 160px;
    }
  }

  .links-side {
    padding-right: 10px;
    padding-left: 10px;

    ul {
      padding: 0;

      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;

      li {
        margin-left: 20px;

        a {
          color: $text;
          text-decoration: none;
        }

        &:hover {
          a {
            color: $primary;
          }
        }

        .router-link-exact-active {
          font-weight: bold;
          color: $primary;
        }
      }
    }
  }
}
.buttons-side-container {
  .buttons-side {
    display: flex;
    justify-content: flex-end;
  }

}

.btn-sign-in,
.btn-white,
.btn-primary {
  padding-right: 0px;
  width: 100px;
}
.btn-sign-in-container {
  display: inline-block;
  position: relative;
  .drop-down {
    position: absolute;
    top: 50px;
    left: 9px;
    .drop-down-body {
      padding: 0;
      margin-top: 4px;
      ul {
        padding: 10px;
        margin: 0;
        list-style-type: none;
        li {
          margin-bottom: 3px;
          a {
            color: $text;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
              color: $primary;
            }
          }
        }
      }
    }
  }
}
</style>