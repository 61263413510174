// import Vue from 'vue';
import {
    plainAxiosInstance
} from '../../axios/index';

const state = {
    firstVisit: true,
    lists: {
        loaction_assets_ids : [],
        dataSet: [],
        asset_types: [],
        districts: [],
        cities: [],
        regions: [],
        coordinates: [],
        properties: [],
        assetProprties: [],
        moved_text: []

    },
    markers: [],

    position: {
        lat: 26.3909581,
        lng: 43.9218021,
        z: 10,
    },

    auth_user: JSON.parse(localStorage.getItem("deb-us")),

    info: {
        noDataFound: false,
        searchMode: false,
        showMovedText: {},
        aboutUs: {
            content: ''
        },
        workingTime: {},
        discription: '',
        planned_chart_url: '',
        order_type: null,
        order_by: null,
        asset_obj: {
            imageUrl: [],
            properties: [],
            asset_type: {},
            location: {}
        }
    },
    loading: false,
    bookingLoading: false,
    f_loading: false,


    searchValues: {
        fromLangPage: true,
        asset: {},
        price: {},
        area: {},
        date: {},
        old: {},
        asset_type: []

    },
    MapData: {
        position: null,
        coordinates: {
            lat: 24.7251919,
            lng: 46.5423774
        },
        zoom: 9
    },
    addValues: {
        owner: {
            agentData: [],
        },
        group: {},
        depts: {},
        locationInfo: {},
        asset: {},
        images: [],
        files: [],
        cleain_data: {},
        assetProprties: []
    },
    paginationData: {
        currentPage: undefined,
        lastPage: undefined
    }

}


const getters = {
    loading: state => state.loading,
    firstVisit: state => state.firstVisit,
    lists: state => state.lists,
    listsAssets: state => state.lists.asset_types,
    info: state => state.info,
    searchValues: state => state.searchValues,
    addValues: state => state.addValues,
    MapData: state => state.MapData,
    paginationData: state => state.paginationData,
    position: state => state.position,
    markers: state => state.markers,
    f_loading: state => state.f_loading,
    bookingLoading: state => state.bookingLoading
}

const actions = {

    async storeData({
        commit,
        state,
        dispatch
    }) {
        commit('setLoading', true)

        return new Promise((resolve, reject) => {
            commit('completeAddValues')
            plainAxiosInstance
                .post("asset", state.addValues)
                .then(response => {

                    commit('setLoading', false)
                    if (response.data.status == "success") {
                        resolve(response)
                        commit('resetAddValues')


                        dispatch('Alert/successMsg', {
                            msg: ' تم إضافة العقار بنجاح !! في إنتظار التعميد'
                        }, {
                            root: true
                        })

                    } else {

                        let massage = response.data.title

                        dispatch('Alert/errorMsg', {
                            msg: massage
                        }, {
                            root: true
                        })
                    }
                })
                .catch(error => {
                    reject(error)
                    dispatch('Alert/errorMsg', '', {
                        root: true
                    })
                })

        })
    },

    async searchByUserFav({
        state,
        commit
    }, user_id) {

        return new Promise((resolve, reject) => {
            commit('setLoading', true)

            plainAxiosInstance
                .get('user_likes/' + user_id)
                .then(response => {
                    console.log(response.data.data)
                    state.paginationData.currentPage = response.data.current_page
                    state.paginationData.lastPage = response.data.last_page
                    state.paginationData.total = response.data.total
                    commit('setLoading', false)
                    commit('setAssetsLandPage', response.data.data)
                    resolve(response)

                })
                .catch(error => {
                    console.log(error.response)
                    reject(error)
                    commit('setLoading', false)

                })
        })
    },

    locatorUserPosition({
        state
    }) {
        navigator.geolocation.getCurrentPosition(
            position => {
                state.position.lat = position.coords.latitude
                state.position.lng = position.coords.longitude
            },
            error => {
                console.log(error.message);
            },
        )
    },

    async performSearch({
        commit,
        state,
        dispatch
    }, page = 1) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true)

            if (state.auth_user != null)
                var user_id = state.auth_user.id
            else user_id = 0

            state.searchValues.user_id = user_id

            plainAxiosInstance
                .post('asset/search?page=' + page, state.searchValues)
                .then(response => {
                   
                    state.info.searchMode = true
                    state.paginationData.currentPage = response.data.assets.current_page
                    state.paginationData.lastPage = response.data.assets.last_page
                    state.paginationData.total = response.data.assets.total
                    commit('setLoading', false)
                    commit('setAssetsLandPage', response.data.assets.data)
                    console.log("search")
                    commit('setAssetCordinate', response.data.locations)
                    state.MapData.zoom = 12
                    resolve(response)

                })
                .catch(error => {
                    reject(error)
                    commit('setLoading', false)
                    dispatch('Alert/errorMsg', '', {
                        root: true
                    })
                })
        })

    },

    setMovedTexts({
        commit
    }) {


        plainAxiosInstance
            .get("get_active_text")
            .then(response => {

                commit('setMovedTexts', response.data)

            })
            .catch(err => {
                commit('setLoading', false)
                console.log(err.response);
            });

    },

    setAboutUs({
        commit
    }) {
        commit('setLoading', true)

        plainAxiosInstance
            .get("get_about_us")
            .then(response => {
                commit('setLoading', false)
                commit('setAboutUs', response.data)

            })
            .catch(err => {
                commit('setLoading', false)
                console.log(err.response);
            });

    },

    setLoading({
        commit
    }, loading) {
        commit('setLoading', loading)
    },

    setWorkingTime({
        commit
    }) {
        plainAxiosInstance
            .get("get_working_time")
            .then(response => {
                commit('setWorkingTime', response.data)

            })
            .catch(err => {
                commit('setLoading', false)
                console.log(err.response);
            });

    },

    setShowMovedTexts({
        commit
    }) {


        plainAxiosInstance
            .get("get_moved_show_text")
            .then(response => {
                commit('setLoading', false)
                commit('setShowMovedTexts', response.data)

            })
            .catch(err => {
                commit('setLoading', false)
                console.log(err.response);
            });

    },

    setAssetTypeProperies({
        commit,
        state
    }, type_id) {

        if (type_id) {

            plainAxiosInstance.get("get_type_properties/" + type_id)
                .then(response => {

                    commit('setProperties', response.data.properties)
                    for (let i = 0; i < state.lists.properties.length; i++) {
                        state.lists.assetProprties.push({
                            value: '',
                            id: '',

                        })
                    }
                })
                .catch(err => {
                    console.log(err.response);
                });

        } else {
            commit('setLoading', false)
            commit('setProperties', [])
        }

    },

    async setAssetsLandPage({
        commit,
        state
    }, page = 1) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true)
                // if(state.firstVisit) {
                //     Vue.prototype.$isLoading(true)
                // }
            if (state.auth_user != null)
                var user_id = state.auth_user.id
            else user_id = 0
            plainAxiosInstance
                .get("get_assets_for_view?page=" + page + '&&order_by=' + state.info.order_by + '&&order_type=' + state.info.order_type + '&&user_id=' + user_id)
                .then(response => {
                    state.firstVisit = false

                    // Vue.prototype.$isLoading(false)
                    state.info.searchMode = false
                    state.paginationData.currentPage = response.data.current_page
                    state.paginationData.lastPage = response.data.last_page
                    state.paginationData.total = response.data.total
                    commit('setAssetsLandPage', response.data.data)
                   
                    commit('setLoading', false)

                    resolve(response)
                })
                .catch(err => {
                    // Vue.prototype.$isLoading(false)
                    reject(err)
                    commit('setLoading', false)
                    console.log(err.response);
                });
        })


    },

    saveSoom({
        dispatch,
        commit
    }, pram) {
        commit('setLoading', true)
        plainAxiosInstance
            .post("add_soom", pram)
            .then(response => {

                commit('setLoading', false)
                if (response.data.status == "success") {

                    dispatch('Alert/successMsg', {
                        msg: 'تم إضافة السومة بنجاح'
                    }, {
                        root: true
                    })
                } else {
                    dispatch('Alert/errorMsg', {
                        msg: response.data.title
                    }, {
                        root: true
                    })
                }

            })
            .catch(err => {
                console.log(err.response);
            });
    },

    async addViewer({
        commit,
        dispatch
    }, pram) {
        commit('setLoading', true)

        return new Promise((resolve, reject) => {
            plainAxiosInstance
                .post('add_asset_view', pram)
                .then(response => {
                    console.log(response.data)
                    commit('setLoading', false)
                    if (response.data.status == "success") {
                        dispatch('Alert/successMsg', '', {
                            root: true
                        })
                        resolve(response)
                    } else {
                        let massage = response.data.title
                        dispatch('Alert/errorMsg', {
                            msg: massage
                        }, {
                            root: true
                        })
                    }

                })
                .catch(error => {
                    commit('setLoading', false)
                    reject(error)
                    dispatch('Alert/errorMsg', {
                        msg: ' server Erorr'
                    }, {
                        root: true
                    })
                })
        })
    },

    async addBooking({
        commit,
        dispatch
    }, pram) {
        commit('setBookingLoading', true)

        return new Promise((resolve, reject) => {
            plainAxiosInstance
                .post('add_booking', pram)
                .then(response => {
                    commit('setBookingLoading', false)

                    if (response.data.status == "success") {
                        dispatch('Alert/successMsg', '', {
                            root: true
                        })
                        resolve(response)
                    } else {
                        let massage = response.data.title
                        dispatch('Alert/errorMsg', {
                            msg: massage
                        }, {
                            root: true
                        })
                    }
                })
                .catch(error => {
                    commit('setLoading', false)
                    reject(error)
                    dispatch('Alert/errorMsg', {
                        msg: ' server Erorr'
                    }, {
                        root: true
                    })
                })
        })
    },

    searchByLocation({
        commit,
        state
    }, coordinates) {
        commit('setLoading', true)
        if (state.auth_user != null)
            var user_id = state.auth_user.id
        else user_id = 0
        plainAxiosInstance
            .get("assets_by_map?lat=" + coordinates.lat() + '&&lng=' + coordinates.lng() + '&&user_id=' + user_id + '&&assets=' + state.lists.loaction_assets_ids )
            .then(response => {

                commit('setLoading', false)
                state.paginationData.currentPage = response.data.current_page
                state.paginationData.lastPage = response.data.last_page
                state.paginationData.total = response.data.total
                commit('setLoading', false)
                commit('setAssetsLandPage', response.data.data)
            })
            .catch(err => {
                console.log(err.response);
            });
    },

    setAssetCordinate({
        commit
    }) {
        plainAxiosInstance
            .get("get_assets_location")
            .then(response => {
                console.log("get asset location")
                console.log(response)
                commit('setAssetCordinate', response.data)
            })
            .catch(err => {
                console.log(err)
                console.log(err.response);
            });
    },

    getAssetTypes({
        commit,

    }) {

        plainAxiosInstance
            .get("get_asset_types")
            .then(response => {

                commit('getAssetTypes', response.data)
            })
            .catch(err => {
                console.log(err.response);
            });
    },

    setAssetData({
        commit,
    }, asset_id) {

        if (state.auth_user != null)
            var user_id = state.auth_user.id
        else user_id = 0

        commit('setLoading', true)

        plainAxiosInstance
            .get("asset/" + asset_id + '?user_id=' + user_id)
            .then(response => {
                commit('setLoading', false)
                commit('setAssetData', response.data[0])
            })

        .catch(err => {
            console.log(err.response);
        });
    },

    setRegions({
        commit
    }) {
        plainAxiosInstance
            .get("get_regions")
            .then(response => {

                commit('setRegions', response.data)
            })
            .catch(err => {
                console.log(err.response);
            });
    },

    setCities({
        commit,
        state
    }, region_id) {

        if (region_id != null) {
            plainAxiosInstance
                .get("region_cities/" + region_id)
                .then(response => {
                    commit('setCities', response.data);
                    for (let i = 0; i < state.lists.regions.length; i++) {
                        if (state.lists.regions[i].id == region_id) {
                            var coordinates = {
                                lat: state.lists.regions[i].lat,
                                lng: state.lists.regions[i].lng
                            }
                            var zoom = 10
                            let data = {
                                coordinates: coordinates,
                                zoom: zoom
                            }
                            commit('setCoordinates', data)
                        }

                    }


                })
                .catch(err => {
                    console.log(err.response);
                });
        } else {
            commit('setCities', []);
            commit('setDistricts', []);
        }

    },

    getAllcities({
        commit
    }) {
        plainAxiosInstance
            .get("city")
            .then(response => {
                commit('setCities', response.data.data);
            })
            .catch(err => {
                console.log(err.response);
            });
    },

    getDistricts({
        commit
    }) {
        plainAxiosInstance
            .get("all_districts")
            .then(response => {
                commit('setDistricts', response.data);
            })
            .catch(err => {
                console.log(err.response);
            });
    },

    setDistricts({
        commit,
        state
    }, city_id) {

        if (city_id != null) {
            plainAxiosInstance
                .get("city_districts/" + city_id)
                .then(response => {
                    commit('setDistricts', response.data);
                    for (let i = 0; i < state.lists.cities.length; i++) {
                        if (state.lists.cities[i].id == city_id) {
                            var coordinates = {
                                lat: state.lists.cities[i].lat,
                                lng: state.lists.cities[i].lng
                            }
                            var zoom = 12
                            let data = {
                                coordinates: coordinates,
                                zoom: zoom
                            }
                            commit('setCoordinates', data)
                        }

                    }

                })
                .catch(err => {
                    console.log(err.response);
                });
        } else commit('setDistricts', []);
    },

    setOfferType({
        state
    }, offer_type) {
        if (offer_type == 1) state.addValues.asset.offer_type = "إيجار";
        else if (offer_type == 2) state.addValues.asset.offer_type = "بيع";
        else state.addValues.asset.offer_type = "مزاد";


    },


    setDistrictsCoordinates({
        commit,
        state
    }, district_id) {

        if (district_id != null) {
            for (let i = 0; i < state.lists.districts.length; i++) {
                if (state.lists.districts[i].id == district_id) {
                    var coordinates = {
                        lat: state.lists.districts[i].lat,
                        lng: state.lists.districts[i].lng
                    }
                    var zoom = 15
                    let data = {
                        coordinates: coordinates,
                        zoom: zoom
                    }
                    commit('setCoordinates', data)
                }

            }
        }
    },

    resetSearchData({
        state
    }) {
        state.searchValues = {
            fromLangPage: true,
            asset: {},
            price: {},
            area: {},
            date: {},
            old: {},
            asset_type: []
        }
        state.lists.properties = []
        state.lists.assetProprties = []
    }

}
const mutations = {
    setBookingLoading(state, loading) {
        state.bookingLoading = loading
    },
    setMovedTexts(state, data) {
        state.lists.moved_text = data
    },

    setAboutUs(state, data) {
        state.info.aboutUs = data
    },

    setWorkingTime(state, data) {
        state.info.workingTime = data
    },

    setShowMovedTexts(state, data) {
        state.info.showMovedText = data
    },

    setLoading(state, loading) {
        state.loading = loading
    },

    addMarker(state, marker) {
        state.markers.push(marker)
    },
    deleteStateMarkers(state){
        state.markers = []
    },

    setProperties(state, data) {
        state.lists.properties = data
    },

    setAssetData(state, data) {

        state.info.asset_obj = data

    },

    setAssetCordinate(state, data) {
        state.lists.coordinates = data
        state.lists.loaction_assets_ids = []
        for (let index = 0; index < data.length; index++) {
            state.lists.loaction_assets_ids.push(data[index].asset_id) 
            
        }

       
    },

    getAssetTypes(state, data) {
        state.lists.asset_types = data
    },

    setAssetsLandPage(state, data) {
        state.lists.dataSet = data
        if (data.length == 0)
            state.info.noDataFound = true
    },

    setFirstLoading(state, loading) {
        state.f_loading = loading
    },

    setRegions(state, data) {
        state.lists.regions = data
    },
    setCities(state, data) {
        state.lists.cities = data
    },
    setDistricts(state, data) {
        state.lists.districts = data
    },

    setCoordinates(state, data) {

        state.MapData.coordinates = data.coordinates
        state.MapData.zoom = data.zoom

    },

    completeAddValues(state) {

        state.addValues.assetProprties = state.lists.assetProprties
        state.addValues.owner.user_id = state.auth_user.id
        state.addValues.owner.link_type = 0
        state.addValues.asset.u_i = 1
    },

    resetAddValues(state) {
        state.addValues = {
            owner: {
                agentData: [],
            },
            group: {},
            depts: {},
            locationInfo: {},
            asset: {},
            images: [],
            files: [],
            cleain_data: {},
            assetProprties: []
        }
        state.lists.properties = []
        state.lists.assetProprties = []
    }




}

export default {

    namespaced: true,
    state,
    getters,
    actions,
    mutations

}