<template>
  <section class="search-bar">
    <div class="containar">
      <div class="search-inputs">
        <div class="search-field" @click="showFavoriteBox()">
          <FormulateInput
            type="search"
            name="sample"
            :placeholder="$t('searchBar.search')"
            class="search-field-text"
          />
          <div
            class="drop-down"
            v-if="favoritesBox"
            id="drop_down"
            v-click-outside="onClickOutside"
          >
            <div class="drop-down-body">
              <div class="drop-down-option" @click="locatorUserPosition">
                <span class="icon">
                  <unicon
                    name="map-marker"
                    fill="#F36A22"
                    width="22"
                    height="22"
                  />
                </span>
                <span class="text"> موقعي الحالي </span>
              </div>
              <div
                class="drop-down-option"
                v-if="user"
                @click="searchByUserFav(user.id)"
              >
                <span class="icon">
                  <unicon
                    name="heart-sign"
                    fill="#F36A22"
                    width="20"
                    height="20"
                  />
                </span>
                <span class="text">المفضلة</span>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="select-input" @click="showFirstBox()">
          <button class="search-select-btn">
            <span class="btn-text"> {{ $t("searchBar.offerType") }} </span>
            <span class="btn-icon">
              <unicon
                name="angle-down"
                fill="#858585"
                height="18"
                width="18"
              ></unicon>
            </span>
          </button>
          <div
            class="drop-down"
            v-if="firstBox"
            id="drop_down"
            @change="preformQuickSearch"
            v-click-outside="onClickOutside"
          >
            <div class="drop-down-body">
              <FormulateInput
                v-model="offer_type"
                :options="{
                  1: $t('searchBar.rent'),
                  2: $t('searchBar.sale'),
                  3: $t('searchBar.acutions'),
                }"
                type="radio"
              />

              <FormulateInput
                v-if="offer_type == 1"
                @change="preformQuickSearch"
                v-model="searchValues.pay_way"
                :options="{
                  شهري: 'شهري',
                  'ربع سنوي': 'ربع سنوي',
                  'نصف سنوي': 'نصف سنوي',
                  سنوي: 'سنوي',
                  الكل: 'الكل',
                }"
                type="select"
                label="طريقة الدفع"
              />
            </div>
          </div>
        </div> -->
        <!-- <div class="select-input" @click="showSecondBox()">

          <button class="search-select-btn">
            <span class="btn-text">
              {{ $t("searchBar.price") }}
            </span>
            <span class="btn-icon">
              <unicon
                name="angle-down"
                fill="#858585"
                height="18"
                width="18"
              ></unicon>
            </span>
          </button>
          <div
            class="drop-down"
            v-if="secondBox"
            id="drop_down"
            v-click-outside="onClickOutside"
          >
            <div class="drop-down-body">
              <FormulateInput
                type="text"
                v-model="priceData.price_from"
                @change="validateNumber"
                name="sample"
                :placeholder="$t('searchBar.priceFrom')"
              />
              <FormulateInput
                type="text"
                v-model="priceData.price_to"
                @change="validateNumber"
                name="sample"
                :placeholder="$t('searchBar.priceTo')"
              />
            </div>
            <div class="drop-down-footer">
              <span class="btn-icon">
                {{ price_error }}
              </span>
              <button
                :class="
                  price_error != ''
                    ? 'btn-primary disabled'
                    : 'btn-primary disabled'
                "
                @click="searchFormPrice"
              >
                تاكيد
              </button>
            </div>
          </div>
        </div> -->
        <div class="select-input offers">
          <button class="search-select-btn" @click="showThirdBox()">
            <span class="btn-text">
              عروض عقارية
              <!-- {{ $t("searchBar.houseType") }} -->
            </span>
            <span class="btn-icon">
              <unicon
                name="angle-down"
                fill="#858585"
                height="18"
                width="18"
              ></unicon>
            </span>
          </button>
          <div
            class="drop-down no-scroll"
            v-if="thirdBox"
            id="drop_down"
            v-click-outside="onClickOutside"
          >
            <div class="drop-down-header">
              <div class="drop-switch">
                <div
                  class="drop-item drop-rent"
                  :class="{ 'active-drop': activeDrop == 1 }"
                  @click="selectDrop(1)"
                >
                  <span class="drop-text"> للايجار </span>
                </div>
                <div
                  class="drop-item drop-sale"
                  :class="{ 'active-drop': activeDrop == 2 }"
                  @click="selectDrop(2)"
                >
                  <div class="drop-text">للبيع</div>
                </div>
              </div>
              <!-- <FormulateInput
                type="checkbox"
                @click="selectedAllEvent"
                v-model="selectedAll"
                :label="$t('searchBar.selectAll')"
              /> -->
            </div>
            <div class="drop-down-body more-drop">
              <div class="search-types">
                <div class="row">
                  <div
                    class="col-6"
                    v-for="ty in lists.asset_types"
                    :key="ty.id"
                  >
                    <div class="search-type" @click="searchAssetOffer(ty.id)">
                      {{ ty.name }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div>
                            <FormulateInput v-if="activeDrop == 1" @change="preformQuickSearch" v-model="searchValues.pay_way" :options="{
                  شهري: 'شهري',
                  'ربع سنوي': 'ربع سنوي',
                  'نصف سنوي': 'نصف سنوي',
                  سنوي: 'سنوي',

                }" type="select" label="طريقة الدفع" />
                        </div> -->
            </div>
          </div>
        </div>

        <div class="select-input">
          <FormulateInput
            type="number"
            name="sample"
            v-model="searchValues.asset.id"
            :placeholder="$t('searchBar.houseNumber')"
            @change="performSearch"
          />
        </div>

        <div class="advanced-search">
          <button class="btn-white" @click="showFourthBox()">المزيد</button>

          <div
            class="drop-down no-scroll"
            v-if="fourthBox"
            id="drop_down"
            v-click-outside="onClickOutside"
          >
            <div class="drop-down-body more-drop">
              <div class="group">
                <div class="group-items colu">
                  <label for="">نوع العقار</label>
                  <v-select
                    @input="setProperies"
                    v-model="searchValues.asset.asset_type_id"
                    :options="formatOptions(lists.asset_types)"
                  />
                </div>
              </div>
              <div class="group row">
                <div class="col-4">
                  <div class="group-heading">تصنيف العقار</div>
                  <div class="group-items">
                    <FormulateInput
                      v-model="searchValues.asset.classfy_asset"
                      class="radios"
                      :options="{
                        سكني: 'سكني',
                        تجاري: 'تجاري',
                        زراعي: 'زراعي',
                        صناعي: 'صناعي',
                        'تجاري و سكني': 'تجاري و سكني',
                      }"
                      type="radio"
                    />
                  </div>
                </div>

                <div class="col-4">
                  <div class="group-heading">نوع الاستعمال</div>
                  <div class="group-items">
                    <FormulateInput
                      v-model="searchValues.asset.usege_type"
                      class="radios"
                      :options="{
                        عزاب: 'عزاب',
                        عوائل: 'عوائل',
                        مكتبي: 'مكتبي',
                        'عزاب و عوائل': 'عزاب و عوائل',
                      }"
                      type="radio"
                    />
                  </div>
                </div>

                <div class="col-4">
                  <div class="group-heading">نوع العرض</div>
                  <div class="group-items">
                    <FormulateInput
                      v-model="searchValues.asset.offer_type"
                      class="radios"
                      :options="{
                        إيجار: 'إيجار',
                        بيع: 'بيع',
                        مزاد: 'مزاد',
                      }"
                      type="radio"
                    />
                  </div>
                </div>
              </div>

              <div class="group row">
                <div class="col-5">
                  <FormulateInput
                    type="text"
                    v-model="searchValues.price.price_from"
                    @change="validateNumber"
                    name="sample"
                    :placeholder="$t('searchBar.priceFrom')"
                  />
                </div>

                <div class="sparate col-2">_____</div>

                <div class="col-5">
                  <FormulateInput
                    type="text"
                    v-model="searchValues.price.price_to"
                    @change="validateNumber"
                    name="sample"
                    :placeholder="$t('searchBar.priceTo')"
                  />
                </div>

                <div class="col-5">
                  <FormulateInput
                    type="text"
                    v-model="searchValues.old.old_from"
                    name="sample"
                    :placeholder="$t('searchBar.oldFrom')"
                  />
                </div>

                <div class="sparate col-2">_____</div>

                <div class="col-5">
                  <FormulateInput
                    type="text"
                    v-model="searchValues.old.old_to"
                    name="sample"
                    :placeholder="$t('searchBar.oldTo')"
                  />
                </div>

                <div class="group-items colu">
                  <label for="">المناطق</label>
                  <v-select
                    class="select2-results__options"
                    style="height: 37px"
                    v-model="searchValues.asset.region_id"
                    :options="formatOptions(lists.regions)"
                    @input="selectRegion"
                  />
                  <label for="">المدن</label>
                  <v-select
                    class="select2-results__options"
                    v-model="searchValues.asset.city_id"
                    :options="formatOptions(lists.cities)"
                    @input="selectCity"
                  />
                  <label for="">الاحياء</label>
                  <v-select
                    class="select2-results__options"
                    v-model="searchValues.asset.district_id"
                    :options="formatOptions(lists.districts)"
                  />

                  <!-- <FormulateInput v-model="searchValues.asset.region_id" @change="selectRegion($event.target.value)" :options="formatOptions(lists.regions)" type="select" label="المنطقة" />
                                <FormulateInput v-model="searchValues.asset.city_id" @change="selectCity($event.target.value)" :options="formatOptions(lists.cities)" type="select" label="المدينة" />
                                <FormulateInput v-model="searchValues.asset.district_id" :options="formatOptions(lists.districts)" type="select" label="الحي" /> -->
                </div>
              </div>

              <div class="group row">
                <div
                  class="col-6"
                  v-for="(property, index) in lists.properties"
                  :key="index"
                >
                  <FormulateInput
                    type="text"
                    @change="lists.assetProprties[index].id = property.id"
                    v-model="lists.assetProprties[index].value"
                    :label="property.name"
                    v-show="property.type == 3"
                  />
                  <FormulateInput
                    @click="lists.assetProprties[index].id = property.id"
                    :label="property.name"
                    v-show="property.type == 4"
                    v-model="lists.assetProprties[index].value"
                    type="checkbox"
                  />
                  <FormulateInput
                    @click="lists.assetProprties[index].id = property.id"
                    :label="property.name"
                    :options="formatPropertyOptions(property.options)"
                    v-show="property.type == 2"
                    v-model="lists.assetProprties[index].value"
                    type="select"
                  />
                </div>
              </div>
            </div>
            <div class="drop-down-footer">
              <button class="btn-primary" @click="advanceSearch">تاكيد</button>
              <button class="btn-white" @click="resetSearchData">
                {{ $t("searchBar.reset") }}
              </button>

              <button class="btn-white" @click="printOffers">PDF</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      priceData: {},
      favoritesBox: false,
      firstBox: false,
      secondBox: false,
      thirdBox: false,
      fourthBox: false,
      offer_type: "",
      price_error: "",
      asset_types: [],
      selectedAll: false,
      activeDrop: 1,
    };
  },
  computed: {
    ...mapGetters("EstateIndex", ["lists", "searchValues"]),
    ...mapGetters("UserIndex", ["user"]),
  },
  methods: {
    ...mapActions("EstateIndex", [
      "getAssetTypes",
      "performSearch",
      "setCities",
      "setDistricts",
      "setAssetTypeProperies",
      "locatorUserPosition",
      "searchByUserFav",
      "setAssetsLandPage",
      "resetSearchData",
    ]),

    setProperies(obj) {
      this.setAssetTypeProperies(obj.value);
    },

    preformQuickSearch() {
      if (this.offer_type == 1) this.searchValues.asset.offer_type = "إيجار";
      else if (this.offer_type == 2) this.searchValues.asset.offer_type = "بيع";
      else this.searchValues.asset.offer_type = "مزاد";
      this.performSearch().then(() => {
        this.firstBox = false;
        this.offer_type = "";
      });
    },
    printOffers() {
      this.searchValues.assetProprties = this.lists.assetProprties;
      this.performSearch().then(() => {
        let elements = [];

        for (let index = 0; index < this.lists.dataSet.length; index++) {
          elements.push(this.lists.dataSet[index].id);
        }

        window.open(
          "https://www.taghtia.sa/?data=" +
            elements +
            "&asset_type=" +
            this.searchValues.asset.asset_type_id.value +
            "&offer_type=" +
            this.searchValues.asset.offer_type +
            "&name=خالد&phone=500465646",
          "_blank"
        );
      });
    },
    formatOptions(list) {
      var options = [];
      for (var item of list) {
        options.push({
          value: item.id,
          label: item.name,
        });
      }
      return options;
    },

    formatPropertyOptions(list) {
      var options = [];
      for (var item of list) {
        options.push({
          value: item.id,
          label: item.option,
        });
      }
      return options;
    },

    advanceSearch() {
      this.searchValues.assetProprties = this.lists.assetProprties;
      this.performSearch().then(() => {
        this.fourthBox = false;
      });
    },

    async selectRegion(val) {
      if (val != null) this.setCities(val.value);
    },
    async selectCity(val) {
      if (val != null) this.setDistricts(val.value);
    },

    selectedAllEvent() {
      if (!this.selectedAll) {
        for (var i in this.lists.asset_types) {
          this.searchValues.asset_type.push(this.lists.asset_types[i].id);
        }
      } else this.searchValues.asset_type = [];
    },

    searchAssetTypes() {
      this.performSearch().then(() => {
        this.resetSelectedTypes();
        this.thirdBox = false;
      });
    },
    resetSelectedTypes() {
      this.searchValues.asset_type = [];
    },
    validateNumber() {
      if (
        parseInt(this.searchValues.price.price_to) <=
        parseInt(this.searchValues.price.price_from)
      ) {
        this.price_error = "يجب ان يكون المبلغ من اصغر من المبلغ الى";
      } else this.price_error = "";
    },
    searchFormPrice() {
      this.searchValues.price = this.priceData;
      this.performSearch().then(() => {
        this.priceData = {};
        this.secondBox = false;
      });
    },

    showModalAdd() {
      this.$refs.addModal.open();
    },
    closeAll() {
      this.favoritesBox = false;
      this.firstBox = false;
      this.secondBox = false;
      this.thirdBox = false;
      this.fourthBox = false;
    },
    showFavoriteBox() {
      this.resetSearchData();
      this.closeAll();
      this.favoritesBox = true;
    },
    // showFirstBox() {
    //     this.resetSearchData();
    //     this.closeAll();
    //     this.firstBox = true;
    // },
    showSecondBox() {
      this.resetSearchData();
      this.closeAll();
      this.secondBox = true;
    },
    showThirdBox() {
      this.resetSearchData();
      this.closeAll();
      this.thirdBox = true;
    },
    showFourthBox() {
      this.resetSearchData();
      this.closeAll();
      this.fourthBox = true;
    },
    onClickOutside() {
      this.closeAll();
    },
    selectDrop(index) {
      this.activeDrop = index;
    },
    searchAssetOffer(type_id) {
      var offer_type = "";
      if (this.activeDrop == 1) offer_type = "إيجار";
      else offer_type = "بيع";

      this.searchValues.asset.asset_type_id = type_id;
      this.searchValues.asset.offer_type = offer_type;

      this.performSearch().then(() => {
        this.thirdBox = false;
      });
    },
  },
  mounted() {
    // this.getAssetTypes();
  },
};
</script>

<style lang="scss">
.search-bar {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  //sass-loader:7.3.1
  .containar {
    width: 98%;
    margin: auto;
  }
}

.sparate {
  text-align: center;
}

.search-inputs {
  display: flex;

  .search-field,
  .select-input,
  .advanced-search {
    margin-left: 10px;
  }

  .search-field {
    position: relative;

    .drop-down {
      width: 350px;

      .drop-down-body {
        padding: 4px 0;

        .drop-down-option {
          padding: 8px 10px;

          .icon {
            margin-left: 4px;
          }

          cursor: pointer;

          &:hover {
            background: #00000010;
          }
        }
      }
    }
  }

  .advanced-search {
    position: relative;

    .btn-white {
      box-shadow: rgb(26, 71, 234) 0px 0px 0px 1px;
    }

    .drop-down {
      width: 500px;
      max-height: 700px;
      overflow: auto;
      left: 0;

      .drop-down-body {
        .group {
          margin-bottom: 20px;

          .group-heading {
            color: $textDark;
            font-weight: bold;
            margin-bottom: 10px;
          }

          .group-items {
            display: flex;
            width: 100%;

            .group-item {
              width: 100%;
            }
          }

          .group-items.colu {
            flex-direction: column;
          }
        }
      }
    }
  }

  .select-input.offers {
    .search-select-btn {
      width: 150px;
    }
  }

  .select-input {
    position: relative;

    .search-select-btn {
      border: 1px solid #cecece;
      background-color: white;
      color: #cecece;
      padding: 7px;
      border-radius: 3px;
      width: 100px;
      display: flex;
      justify-content: space-between;

      &:hover {
        border: 1px solid $text;
        color: $text;
      }

      &:focus {
        outline: 0;
      }
    }
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}

.drop-down {
  max-height: 400px;
  overflow: auto;

  &:before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 10px 12px;
    border-color: transparent transparent #ccc transparent;
    right: 5%;
    /* TOP IS DOUBLE THE HEIGHT OF THE BORDER */
    top: -20px;
  }

  position: absolute;
  top: 50px;
  min-width: 300px;
  border: 1px solid #ccc;
  background: white;
  z-index: 999;
  border-radius: 4px;

  .drop-down-header,
  .drop-down-body {
    border-bottom: 1px solid #ccc;
  }

  .drop-down-header {
    font-weight: bold;
    padding: 15px;
  }

  .drop-down-body {
    padding: 20px 10px 0px 10px;

    .formulate-input {
      margin-bottom: 1rem;
    }
  }

  .drop-down-footer {
    padding: 15px;
  }
}

.advanced-search {
  .no-scroll {
    overflow: visible;

    .more-drop {
      overflow-y: scroll;
      height: 350px;
    }
  }
}

.no-scroll {
  overflow: visible;

  .more-drop {
    overflow-y: scroll;
    height: 280px;
  }
}

.drop-switch {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 1px;

  .drop-item {
    transition: all 0.7s;
    cursor: pointer;
    width: 100%;
    text-align: center;
    padding: 4px;
    font-weight: normal;
  }

  .active-drop {
    background: $primary;
    color: white;
    border-radius: 6px;
    // overflow: hidden;
  }
}

.search-types {
  .search-type {
    // border: 1px solid #ccc;
    padding: 3px;
    border-radius: 10px;
    margin: 1px;
    cursor: pointer;
    width: 100px;
    text-align: center;
    margin-bottom: 6px;
    background-color: white;
    color: $primary;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);

    &:hover {
      background-color: $primary;
      color: white;
    }
  }
}
</style>
