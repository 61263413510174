<template>
<section class="preview-modal">
    <h2>طلب معاينة</h2>
    <FormulateForm @submit="submitView">
        <FormulateInput v-model="view_date" type="date" label="التاريخ" validation="required" />
        <FormulateInput v-model="view_time" type="time" label="الزمن" validation="required" />
        <FormulateInput v-model="des" type="textarea" label="كتابة ملاحظات" />
        <div class="submit-container">
            <button type="submit" class="preview-submit">طلب</button>
        </div>
    </FormulateForm>
    <loading-overlay color="#F36A22" :active.sync="loading" :is-full-page="false" :can-cancel="true">
    </loading-overlay>
</section>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
    mapActions,
    mapGetters
} from "vuex";

export default {
    data() {
        return {
            addValues: {
                view_date: undefined,
                view_time: undefined,
                user_id: JSON.parse(localStorage.getItem("deb-us")).id,
                des: undefined,
                u_i: 1,
                asset_id: this.$route.params.id
            },
            view_date: undefined,
            view_time: undefined,
            des: undefined,
            auth_user: JSON.parse(localStorage.getItem("deb-us")),
        };
    },

    computed: {
        ...mapGetters("EstateIndex", [
            "loading",
        ]),
    },

    components: {
        "loading-overlay": Loading
    },

    methods: {
        ...mapActions("EstateIndex", [
            "addViewer",

        ]),
        submitView() {

            this.addValues.view_date = this.view_date;
            this.addValues.view_time = this.view_time;
            this.addValues.des = this.des;
            this.addViewer(this.addValues).then(() => {
               this.$modal.hide("preview-modal");
            })
            // $emit('close')
        },
    },
};
</script>

<style lang="scss" scoped>
.preview-modal {
    direction: rtl;
    padding: 20px;
}

.submit-container {
    display: flex;
    justify-content: flex-end;
}

.preview-submit {
    background-color: $primary;
    color: white;
    padding: 4px 18px;
    border: none;
    border-radius: 4px;
}
</style>
