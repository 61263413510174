const ar = {
    home: {
        showMap: 'عرض الخريطة',
        showEstates: 'عرض العقارات',
    },
    navBar: {
        home: "الرئيسية",
        about: "عنا",
        contact: "تواصل معنا",
        join: "انضم إلينا",
        rates: "طلب تقييم عقاري",
        request: "تقديم طلب",
        addHouse: "اضافة عقار",
        signIn: " الدخول",
        myAccount: "حسابي",
        privcay: "سياسة الخصوصية",
        terms: "الشروط والأحكام",
        complaints: "الشكاوي"

    },
    searchBar: {
        search: "بحث",
        rent: "ايجار",
        sale: "بيع",
        acutions: "مزاد",
        price: "السعر",
        priceFrom: "السعر من",
        priceTo: "السعر الى",
        oldFrom: "عمر العقار من",
        oldTo: "عمر العقار الى",
        houseType: "نوع العقار",
        villa: "فيلا",
        restRoom: "استراحة",
        shop: "محل",
        workshop: "ورشة",
        duplex: "دوبلكس",
        land: "ارض",
        building: "مبنى",
        apartment: "شقة",
        houseNumber: "رقم العقار",
        advancedSearch: "بحث متقدم",
        selectAll: "تحديد الكل",
        reset: "تفريغ",
        confirm: "تأكيد",
        offerType: "عروض"
    },
    cards: {
        totalResults: 'عدد النتائج',
        sortBy: "ترتيب حسب",
        priceLow: "السعر (الأدنى أولا)",
        priceHigh: "السعر (الأعلى أولا)",
        squareFeet: "مساحة المنزل",
        results: "نتائج",
        details: "تفاصيل",
        noResults: "لا توجد نتائج مطابقة",
        noResultsDetails: "لا توجد نتائج مطابقة لبحثك حاول تغيير البحث",
        previous: "السابق",
        next: "التالي",
        please: "الرجاء",
        login: "تسجيل الدخول",
        register: " تسجيل حساب",
        need: "يجب عليك تسجيل الدخول اولا",
        first: "اولا او القيام ب",
        day: 'يوم',
        days: 'ايام',
        month: 'شهر ',
        months: 'اشهر',
        hower: 'ساعة',
        minute: 'دقيقه ',
        second: 'ثانية',
        newAsset: 'الاحدث إضافة',
    },
    login: {
        login: "تسجيل الدخول",
        username: "رقم الهوية",
        yourUserName: "رقم الهوية الخاص بك",
        yourPassword: "كلمة المرور الخاصة بك",
        password: "كلمة المرور",
        forgotPassword: "هل نسيت كلمة المرور؟",
        register: "تسجيل",
        callUs: "اتصل بنا",
        terms: "الشروط والأحكام",
        privacyPolicy: "سياسة الخصوصية",
        fallow: "تـــابعنـــــــــــــــــــا علـــــى",
    }

}
export default ar