<template>
  <section class="not-logged-modal">
    <!-- <modal
      name="not-logged-modal"
      :adaptive="true"
      height="200px"
      :classes="['not-logged-modal']"
    > -->
      <div>
        <div class="heading">
          <h3>{{ $t("cards.need") }}</h3>
        </div>
        <div class="text">
          {{ $t("cards.please") }}
          <router-link to="/login" @click.native="hideModals"> {{ $t("cards.login") }} </router-link>
          {{ $t("cards.first") }}
          <router-link to="/register" @click.native="hideModals"> {{ $t("cards.register") }} </router-link>
        </div>
      </div>
    <!-- </modal> -->
  </section>
</template>

<script>
export default {
  methods: {
    hideModals(){
      this.$modal.hideAll();
    }
  }
};
</script>

<style>
.not-logged-modal {
  padding: 20px;
  direction: rtl;
}
</style>
