<template>
  <section class="not-logged-modal">
    <!-- <modal
      name="not-logged-modal"
      :adaptive="true"
      height="200px"
      :classes="['not-logged-modal']"
    > -->
      <div>
        
        <div class="text">
            <img  :src="info.planned_chart_url" alt="" />
        </div>
      </div>
    <!-- </modal> -->
  </section>
</template>

<script>
import {
    mapGetters,
} from "vuex";
export default {
    computed: {
        ...mapGetters("EstateIndex", ["info"]),
    },
  methods: {
    hideModals(){
      this.$modal.hideAll();
    }
  }
};
</script>

<style>
.not-logged-modal {
  padding: 20px;
  direction: rtl;
}
</style>
