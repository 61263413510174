<template>
  <section class="not-logged-modal">
    <button @click="$emit('close')" class="close-btn">X</button>
    <!-- <modal
      name="not-logged-modal"
      :adaptive="true"
      height="200px"
      :classes="['not-logged-modal']"
    > -->
      <div>
        
        <div class="text" v-html="info.discription">
         
        </div>
      </div>
    <!-- </modal> -->
  </section>
</template>

<script>
import {
    mapGetters,
} from "vuex";
export default {
    computed: {
        ...mapGetters("EstateIndex", ["info"]),
    },
  methods: {
    hideModals(){
      this.$modal.hideAll();
    }
  }
};
</script>

<style>
.not-logged-modal {
  padding: 20px;
  direction: rtl;
  height: 100%;
  overflow: scroll;
}
.close-btn {
  background-color: transparent;
  border: none;
}
</style>
