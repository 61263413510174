<template>
  <div
    class="loading"
    :style="`background-color: ${options.bg};`"
    v-show="firstVisit && loading"
  >
    <div class="loading-container">
      <div class="loading-logo">
        <img
          src="@/assets/logos/logoHome.png"
          alt=""
          style="height: 30px; width: 130px"
        />
      </div>

      <div class="loading-img">
        <img src="@/assets/home-loading.gif" alt="" />
      </div>

      <div class="loading-text">جار التحميل...</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "VueLoading",
  data() {
    return {
      isLoading: true,
      options: {
        bg: "#fff",
      },
    };
  },
  computed: {
    ...mapGetters("EstateIndex", ["firstVisit", "loading"]),
  },
};
</script>

<style scoped lang="scss">
.loading {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 99999 !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading i {
  position: absolute;
  opacity: 1;
}
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .loading-logo {
    img {
      width: 220px;
    }
  }
  .loading-img {
    img {
      height: 300px;
      width: 300px;
    }
  }
  .loading-text {
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
