<template>
<div class="add-estate">
    <div class="modal-header">
        <h2>اضافة عقار</h2>
        <unicon name="multiply" fill="#ffffff" height="18" width="18" class="close-btn" @click="$emit('close')"></unicon>
    </div>
    <FormulateForm @submit="saveAsset
    ">
        <div class="details">
            <div class="heading">تفاصيل العقار</div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="input-field">
                        <FormulateInput :validation-messages="{required: 'يجب إختيار نوع العقار'}" validation="required" @change="setAssetTypeProperies(addValues.asset.asset_type_id)" v-model="addValues.asset.asset_type_id" :options="formatOptions(lists.asset_types)" type="select" label="اختر نوع العقار" />
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="input-field">
                        <FormulateInput v-model="addValues.asset.asset_old" type="number" label="عمر العقار" />
                    </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="input-field">
                        <FormulateInput v-model="addValues.asset.asset_area" type="text" label="مساحة العقار" />
                    </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="input-field">
                        <FormulateInput v-model="addValues.asset.spacail_des" type="text" label="مكان المفتاح" />
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div class="radio-group">
                        <div class="sub-heading">تصنيف العقار</div>
                        <FormulateInput :validation-messages="{required: 'يجب إختيار تصنيف العقار'}" validation="required" v-model="addValues.asset.classfy_asset" class="radios" :options="{
                'سكني':   'سكني',
                'تجاري': 'تجاري',
                'زراعي': 'زراعي',
                'صناعي': 'صناعي',
              }" type="radio" />
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div class="radio-group">
                        <div class="sub-heading">نوع الاستعمال</div>
                        <FormulateInput :validation-messages="{required: 'يجب إختيار نوع الاستعمال'}" validation="required" v-model="addValues.asset.usege_type" class="radios" :options="{
                'عذاب': 'عزاب',
                'عوائل': 'عوائل',
                'مكتبي': 'مكتبي',
              }" type="radio" />
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div class="radio-group">
                        <div class="sub-heading">نوع العرض</div>
                        <FormulateInput :validation-messages="{required: 'يجب إختيار نوع العرض'}" validation="required" @click="offerType" v-model="addValues.asset.offer_type" class="radios" :options="{
                'إيجار': 'إيجار',
                'بيع': 'بيع',
                'مزاد': 'مزاد',
              }" type="radio" />
                    </div>
                </div>
                <div class="rent-form">
                    <div class="row rent-row">
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form">
                                <FormulateInput :validation-messages="{required: 'يجب إدخال سعر العقار'}" validation="required" v-if="addValues.asset.offer_type == 'إيجار'" v-model="addValues.asset.offer_price" type="text" name="sample" label="سعر الايحار" />
                                <FormulateInput v-if="addValues.asset.offer_type == 'بيع'" v-model="addValues.asset.offer_price" type="text" name="sample" label="سعر البيع" />
                                <FormulateInput v-if="addValues.asset.offer_type == 'بيع'" v-model="addValues.asset.soom_price" type="text" name="sample" label="سعر السوم" />
                                <FormulateInput v-if="addValues.asset.offer_type == 'إيجار'" v-model="addValues.asset.pay_way" :options="{
                              'شهري': 'شهري',
                              'ربع سنوي': 'ربع سنوي',
                              'نصف سنوي': 'نصف سنوي',
                              'سنوي': 'سنوي',
                              'الكل': 'الكل',
                            }" type="select" label="طريقة الدفع" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="estate-features">
                    <div class="heading">خصائص العقار</div>
                    <br>
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" v-for="(property, index) in lists.properties" :key="index">
                            <FormulateInput type="text" @change="lists.assetProprties[index].id = property.id" v-model="lists.assetProprties[index].value" :label="property.name" v-show="property.type == 3" />
                            <FormulateInput @click="lists.assetProprties[index].id = property.id" :label="property.name" v-show="property.type == 4" v-model="lists.assetProprties[index].value" type="checkbox" :value="1" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="estate-location">
            <div class="heading">الموقع الجغراقي </div>
            <br>
            <div class="row">

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="form-side">
                        <FormulateInput validation="required" v-model="addValues.asset.region_id" @change="selectRegion($event.target.value)" :options="formatOptions(lists.regions)" type="select" label="المنطقة" />
                        <FormulateInput validation="required" v-model="addValues.asset.city_id" @change="selectCity($event.target.value)" :options="formatOptions(lists.cities)" type="select" label="المدينة" />
                        <FormulateInput validation="required" v-model="addValues.asset.district_id" @change="selectDistrict($event.target.value)" :options="formatOptions(lists.districts)" type="select" label="الحي" />
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <label for=""> اضغط على موقع القعار في الخريطة </label>
                    <div class="estate-map-side">
                        <div id="addMap"></div>
                    </div>

                </div>
            </div>
        </div>

        <div class="footer">
            <button class="btn-white" @click="$emit('close')">الغاء</button>
            <button class="btn-primary" type="submit">تأكيد</button>
        </div>
    </FormulateForm>

    <loading-overlay color="#F36A22" :active.sync="loading" :is-full-page="false" :can-cancel="true">
    </loading-overlay>
</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    // components: {
    //   UploadImages,
    // },
    data() {
        return {
            addMap: undefined,
            center: {
                lat: 24.8184709,
                lng: 46.8028893,
                z: 20,
            },
            selectedRegion: undefined,
            selectedCity: undefined,
            selectedDistrict: undefined,
            offer_type: "",
        };
    },
    computed: {
        ...mapGetters("EstateIndex", [
            "lists",
            "addValues",
            "MapData",
            "listsAssets",
            "loading"
        ]),
    },
    components: {
        "loading-overlay": Loading
    },
    methods: {
        ...mapActions("EstateIndex", [
            "setCities",
            "setDistricts",
            "performSearch",
            "setDistrictsCoordinates",
            "setAssetTypeProperies",
            "storeData"
        ]),
        formatOptions(list) {
            var options = [];
            for (var item of list) {
                options.push({
                    value: item.id,
                    label: item.name,
                });
            }
            return options;
        },
        offerType() {
            this.setOfferType(this.offer_type)
        },
        closeAddModal() {},
        async selectRegion(val) {
            this.setCities(val);
            var geocoder = new window.google.maps.Geocoder();
            const selectedRegion = this.lists.regions.find(
                (region) => region.id == val
            );
            var cords = await geocoder.geocode({
                address: `${selectedRegion.name} السعودية`,
            });
            this.addMap.setCenter(cords.results[0].geometry.location);
        },
        async selectCity(val) {
            this.setDistricts(val);
            const selectedCity = this.lists.cities.find((city) => city.id == val);
            var geocoder = new window.google.maps.Geocoder();
            var cords = await geocoder.geocode({
                address: ` ${selectedCity.name}`,
            });
            this.addMap.setCenter(cords.results[0].geometry.location);
        },
        async selectDistrict(val) {
            this.setDistrictsCoordinates(val);
            this.addMap.setCenter(this.MapData.coordinates);
            this.addMap.setZoom(this.MapData.zoom);
        },

        initMap() {
            this.addMap = new window.google.maps.Map(
                document.getElementById("addMap"), {
                    center: this.center,
                    zoom: 8,
                }
            );

            this.addMap.addListener("click", (mapsMouseEvent) => {
                this.placeMarker(mapsMouseEvent.latLng);
                this.setMapPosition(mapsMouseEvent);
            });
        },

        placeMarker(location) {
            new window.google.maps.Marker({
                position: location,
                map: this.addMap
            });
        },

        createMarker(e) {
            var svgMarker = {
                path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
                fillColor: "red",
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
                scale: 0.4,
            };
            var marker = new window.google.maps.Marker({
                position: e.latLng,
                map: this.addMap,
                icon: svgMarker,
            });

            return marker;
        },

        setMapPosition(e) {
            let location = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
            };
            this.addValues.locationInfo = location;
        },

        saveAsset() {
            this.storeData().then(() => {
                this.$router.push({
                    name: 'home'
                })
            })
        }
    },
    mounted() {
        this.initMap();
    },
};
</script>

<style lang="scss">
@media only screen and (max-width: 768px) {
    .estate-map-side {
        height: 400px !important;
        margin-top: 10px !important;
    }
}

.add-estate {
    direction: rtl;

    .modal-header {
        border-bottom: 1px solid #c0c0c080;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $primary;
        color: white;

        .close-btn {
            cursor: pointer;
        }

        h2 {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    .details {
        padding: 20px;
        border-bottom: 1px solid #ccc;

        .input-field {
            padding: 10px;
        }

        .formulate-input-label {
            color: $text;
        }

        .rent-form {
            .rent-row {
                justify-content: flex-end;
            }

            width: 100%;

            .form {
                background-color: #c0c0c020;
                padding: 20px;
                border: 1px solid #ccc;
                border-radius: 4px;
            }
        }

        .estate-features {
            width: 100%;
        }
    }

    .heading {
        text-align: start;
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 18px;
    }

    .sub-heading {
        font-weight: bold;
        color: $textDark;
        text-align: start;
    }

    .radio-group {
        margin-top: 20px;

        .radios {
            margin-top: 15px;
        }
    }

    .upload-images {
        padding: 20px;

        .upload-input {
            padding: 15px 30px;
        }
    }

    .estate-location {
        padding: 20px;
        border-bottom: 1px solid #ccc;

        .form-side {
            padding: 5px;
        }

        .estate-map-side {
            padding: 5px;
            height: 100%;

            #addMap {
                height: 100%;
                width: 100%;
                border-right: 1px solid #ccc;

                .map-view {
                    height: 100%;
                }
            }
        }
    }

    .footer {
        border-top: 1px solid #ccc;
        margin-top: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: end;

        button {
            font-weight: bold;
        }
    }
}

// {https://nominatim.openstreetmap.org/search.php?q=%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6&polygon_geojson=1&format=json}
</style>
