import Swal from 'sweetalert2'
window.Swal = Swal;

const Success = Swal.mixin({
  success: true,
  position: 'center',
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: false,

})
window.Success = Success;

const actions = {
    successMsg(data) {
       
        Success.fire({
            icon: 'success',
            title: data.msg
        });
    },
    errorMsg( data) {
        
        Success.fire({
            icon: 'error',
            title: data.msg
        });
    },

}



export default {
    namespaced: true,
    actions,

}