import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
const API_URL = 'https://dashbord-panel.aldabaan.sa/api/v1/';

const plainAxiosInstance = axios.create({
    baseURL: API_URL,
    //   withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

export { plainAxiosInstance };