const en = {
    home: {
        showMap: 'Show Map',
        showEstates: 'Show Estates',
    },
    navBar: {
        home: "Home",
        about: "About us",
        contact: "Contact us",
        join: "Join us",
        rates: "Rate estate",
        request: "Make a request",
        addHouse: "Add house",
        signIn: "Sign in",
        myAccount: "account",
        privcay: "Privacy Policy",
        terms: "Terms of Use",
        complaints: "Complaints"
    },
    searchBar: {
        search: "Search",
        rent: "Rent",
        sale: "Sale",
        price: "Price",
        oldFrom: "Old From",
        oldTo: "Old To",
        acutions: "acutions",
        priceFrom: "Price from",
        priceTo: "Price to",
        houseType: "Type",
        villa: "Villa",
        restRoom: "Rest room",
        shop: "Shop",
        workshop: "Workshop",
        duplex: "Duplex",
        land: "Land",
        building: "Building",
        apartment: "Apartment",
        houseNumber: "House number",
        advancedSearch: "Advanced",
        selectAll: "Select all",
        reset: "Reset",
        confirm: "Confirm",
        offerType: "عروض"
    },
    cards: {
        totalResults: 'Total Result',
        sortBy: "Sort by",
        priceLow: "Price (low first)",
        priceHigh: "Price (high first)",
        squareFeet: "Square feet",
        results: "Results",
        details: "Details",
        noResults: "No results",
        noResultsDetails: "No results, try changing the search criteria",
        previous: "Previous",
        next: "Next",
        please: "Please",
        login: " Login",
        register: " Register",
        need: "You need to login first",
        first: "first or try to",
        month: 'month ',
        months: 'months',
        hower: 'hower',
        minute: 'minute ',
        second: 'second',
        newAsset: 'newest adding',
    },
    login: {
        login: "Login",
        username: "Username",
        yourUserName: "Your username",
        yourPassword: "Your password",
        password: "Password",
        forgotPassword: "Forgot password?",
        register: "Register",
        callUs: "Call us",
        terms: "Terms & conditions",
        privacyPolicy: "Privacy policy",
        fallow: "Fallow Us"

    }
}
export default en